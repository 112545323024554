import { FetchError } from "ofetch";
// Custom wrapper around Nuxt's ofetch to handle Laravel's CSRF protection, errors, etc.

export async function sendRequest<T>(
  fetchablePromise: Promise<T>,
  onSuccessHandler?: (data?: T) => any,
  onValidationErrorHandler?: (errors, message: string) => any
): Promise<{ data: T | null; errors }> {
  try {
    const data = await fetchablePromise

    await onSuccessHandler?.(data)

    return {
      data,
      errors: null
    }
  } catch (error) {
    if (! (error instanceof FetchError)) {
      throw error
    }

    if (error.response?.status !== 422) {
      throw error
    }

    const errors = error.data?.errors || {}
    const message = error.data?.message || ''

    await onValidationErrorHandler?.(errors, message)

    return {
      data: null,
      errors
    }
  }
}
