import validate from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/sean/Development/dreamingunicorns/frontend/middleware/admin.ts"),
  auth: () => import("/home/sean/Development/dreamingunicorns/frontend/middleware/auth.ts"),
  guest: () => import("/home/sean/Development/dreamingunicorns/frontend/middleware/guest.ts"),
  supplier: () => import("/home/sean/Development/dreamingunicorns/frontend/middleware/supplier.ts"),
  unverified: () => import("/home/sean/Development/dreamingunicorns/frontend/middleware/unverified.ts"),
  verified: () => import("/home/sean/Development/dreamingunicorns/frontend/middleware/verified.ts")
}