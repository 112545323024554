<script setup>

import {onMounted} from "vue";

const props = defineProps({
  error: Object
})

onMounted(() => {
  document.querySelector('html').classList.remove('has-navbar-fixed-top')
})

</script>

<template>
  <div class="error-page">
    <nuxt-img
        src="/images/footer-logo.png"
        alt="Dreaming Unicorns Logo"
        sizes="xs:100"
    />

    <div v-if="! error.data">
      <h1>Oh, no.</h1>
      <p>There was an error.</p>
      <a class="button is-primary is-medium" href="/">Back to Home</a>
    </div>
    <div v-else>
      <h1>Oh, no.</h1>
      <p>Sorry, we don't stock this product anymore !</p>
      <br>
      <p>We hope you love our new range, though.</p>
      <a class="button is-primary is-medium" href="/shop">Shop</a>
    </div>

    <div v-if="! error.data" class="details">
      {{ error.statusCode }}
      <br>
      {{ error.statusMessage }}
    </div>

  </div>
</template>

<style scoped>
.details {
  margin-top: 5rem;
}
img {
  margin-bottom: 2rem;
}

.error-page {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  margin-top: 0px;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.button {
  margin-top: 3rem;
}
</style>
