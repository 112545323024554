import {useAuthStore} from "@/stores/auth"

export default defineNuxtPlugin(() => {

    if (process.client) {
        const auth = useAuthStore()

        if (auth.user) {
            return
        }

        auth.refresh()
    }
})

