// plugins/google-analytics.js

export default defineNuxtPlugin(async () => {
//export default async function setupGoogleAnalytics() {
    // Load Google Analytics only on the client-side
    if (import.meta.env.SSR) {
        return;
    }

    await import('https://www.googletagmanager.com/gtag/js?id=G-00C1YVBPF9');

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'G-00C1YVBPF9');
})
