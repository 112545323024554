import { $fetch, FetchOptions, FetchError } from 'ofetch'
import {useAuthStore} from '~/stores/auth'

const CSRF_COOKIE = 'XSRF-TOKEN'
const CSRF_HEADER = 'X-XSRF-TOKEN'

const HTTP_STATUS_NOT_AUTH = [401, 419]
const csrfMethods = ['post', 'delete', 'put', 'patch']

type ResponseType = keyof ResponseMap | 'json'

interface ResponseMap {
  blob: Blob
  text: string
  arrayBuffer: ArrayBuffer
}

type BackendRequestOptions<R extends ResponseType> = FetchOptions<R> & {
  redirectIfNotAuthenticated?: boolean
  method?: string
  headers?: any
  body?: any
};

async function initCsrfCookie() {
  const { apiUrl: backendUrl } = useRuntimeConfig().public

  document.cookie = CSRF_COOKIE + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';

  await $fetch('/sanctum/csrf-cookie', {
    baseURL: backendUrl,
    credentials: 'include',
  })
}

function getCookieValue(name: string) {
  const match = document.cookie.match(
      new RegExp("(^|;\\s*)(" + name + ")=([^;]*)")
  );
  return match ? decodeURIComponent(match[3]) : null;
}

export async function $backendRequest<T, R extends ResponseType = 'json'>(
  path: RequestInfo,
  {
    redirectIfNotAuthenticated = true,
    ...options
  }: BackendRequestOptions<R> = {}
) {
  const { apiUrl: backendUrl, baseUrl: frontendUrl } = useRuntimeConfig().public
  let token = await useCookie(CSRF_COOKIE).value

  if (process.client &&
      csrfMethods.includes(options?.method?.toLowerCase() ?? '')
  ) {
    await initCsrfCookie()
    token = await getCookieValue(CSRF_COOKIE);
  }

  let headers: any = {
    ...options?.headers,
    ...(token && { [CSRF_HEADER]: token }),
    Referer: backendUrl,
    Accept: 'application/json',
  }

  let body = options?.body

  if (process.server) {
    headers = {
      ...headers,
      ...useRequestHeaders(['cookie']),
      referer: backendUrl,
    }
  }

  try {
    return await $fetch<T, R>(path, {
      baseURL: backendUrl,
      ...options,
      headers,
      credentials: 'include',
      body,
    })
  } catch (error) {
    if (! (error instanceof FetchError)) {
      throw error
    }

    const status = error.response?.status ?? 0

    if (redirectIfNotAuthenticated &&
        HTTP_STATUS_NOT_AUTH.includes(status)
    ) {
      const auth = useAuthStore()
      auth.clearUser()

      const router = useRouter()
      await router.push('/login')
    }

    throw error
  }
}
