import { default as _91_46_46_46slug_93ROD3BSEhSFMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/[...slug].vue?macro=true";
import { default as _404tb2gkvxQYkMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/404.vue?macro=true";
import { default as aboutoJOJpdTMU8Meta } from "/home/sean/Development/dreamingunicorns/frontend/pages/about.vue?macro=true";
import { default as editQtsvZQPez6Meta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/collection/[id]/edit.vue?macro=true";
import { default as newCcp3OklQVeMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/collection/new.vue?macro=true";
import { default as detailsBax7Qa8gnUMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/orders/[id]/details.vue?macro=true";
import { default as indexLizTWhqZ9hMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/orders/index.vue?macro=true";
import { default as editIeAdB7pRO4Meta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/products/[id]/edit.vue?macro=true";
import { default as indexcESM4D7Y1xMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/products/index.vue?macro=true";
import { default as newjYRvrVNXqHMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/products/new.vue?macro=true";
import { default as editqHdmvclbQcMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/suppliers/[id]/edit.vue?macro=true";
import { default as show8exgddYrsfMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/suppliers/[id]/show.vue?macro=true";
import { default as indextyzo8qSzi8Meta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/suppliers/index.vue?macro=true";
import { default as new36e5tLFmBhMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/admin/suppliers/new.vue?macro=true";
import { default as blogZNyjgH1hklMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/blog.vue?macro=true";
import { default as cartK246qPAK3VMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/cart.vue?macro=true";
import { default as successyvtl40Wl91Meta } from "/home/sean/Development/dreamingunicorns/frontend/pages/checkout/success.vue?macro=true";
import { default as contact0xQw0YRwAOMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/contact.vue?macro=true";
import { default as dashboard1n03aVKS7OMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/dashboard.vue?macro=true";
import { default as guide05OM1pj1ybMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/guide.vue?macro=true";
import { default as indexz8nWmkGksyMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/index.vue?macro=true";
import { default as loginRrXON8r6HRMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/login.vue?macro=true";
import { default as logoutysoAzVob8qMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/logout.vue?macro=true";
import { default as _91_46_46_46slug_93lw7cuIfOSdMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/product/[...slug].vue?macro=true";
import { default as shopJ3DBTEun14Meta } from "/home/sean/Development/dreamingunicorns/frontend/pages/shop.vue?macro=true";
import { default as ordersAFxWsjvOQdMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/supplier/orders.vue?macro=true";
import { default as unauthorizedt7w4x3LS9XMeta } from "/home/sean/Development/dreamingunicorns/frontend/pages/unauthorized.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ROD3BSEhSFMeta?.name ?? "slug",
    path: _91_46_46_46slug_93ROD3BSEhSFMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ROD3BSEhSFMeta || {},
    alias: _91_46_46_46slug_93ROD3BSEhSFMeta?.alias || [],
    redirect: _91_46_46_46slug_93ROD3BSEhSFMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _404tb2gkvxQYkMeta?.name ?? "404",
    path: _404tb2gkvxQYkMeta?.path ?? "/404",
    meta: _404tb2gkvxQYkMeta || {},
    alias: _404tb2gkvxQYkMeta?.alias || [],
    redirect: _404tb2gkvxQYkMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: aboutoJOJpdTMU8Meta?.name ?? "about",
    path: aboutoJOJpdTMU8Meta?.path ?? "/about",
    meta: aboutoJOJpdTMU8Meta || {},
    alias: aboutoJOJpdTMU8Meta?.alias || [],
    redirect: aboutoJOJpdTMU8Meta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: editQtsvZQPez6Meta?.name ?? "admin-collection-id-edit",
    path: editQtsvZQPez6Meta?.path ?? "/admin/collection/:id()/edit",
    meta: editQtsvZQPez6Meta || {},
    alias: editQtsvZQPez6Meta?.alias || [],
    redirect: editQtsvZQPez6Meta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/collection/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: newCcp3OklQVeMeta?.name ?? "admin-collection-new",
    path: newCcp3OklQVeMeta?.path ?? "/admin/collection/new",
    meta: newCcp3OklQVeMeta || {},
    alias: newCcp3OklQVeMeta?.alias || [],
    redirect: newCcp3OklQVeMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/collection/new.vue").then(m => m.default || m)
  },
  {
    name: detailsBax7Qa8gnUMeta?.name ?? "admin-orders-id-details",
    path: detailsBax7Qa8gnUMeta?.path ?? "/admin/orders/:id()/details",
    meta: detailsBax7Qa8gnUMeta || {},
    alias: detailsBax7Qa8gnUMeta?.alias || [],
    redirect: detailsBax7Qa8gnUMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/orders/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: indexLizTWhqZ9hMeta?.name ?? "admin-orders",
    path: indexLizTWhqZ9hMeta?.path ?? "/admin/orders",
    meta: indexLizTWhqZ9hMeta || {},
    alias: indexLizTWhqZ9hMeta?.alias || [],
    redirect: indexLizTWhqZ9hMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/orders/index.vue").then(m => m.default || m)
  },
  {
    name: editIeAdB7pRO4Meta?.name ?? "admin-products-id-edit",
    path: editIeAdB7pRO4Meta?.path ?? "/admin/products/:id()/edit",
    meta: editIeAdB7pRO4Meta || {},
    alias: editIeAdB7pRO4Meta?.alias || [],
    redirect: editIeAdB7pRO4Meta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/products/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexcESM4D7Y1xMeta?.name ?? "admin-products",
    path: indexcESM4D7Y1xMeta?.path ?? "/admin/products",
    meta: indexcESM4D7Y1xMeta || {},
    alias: indexcESM4D7Y1xMeta?.alias || [],
    redirect: indexcESM4D7Y1xMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/products/index.vue").then(m => m.default || m)
  },
  {
    name: newjYRvrVNXqHMeta?.name ?? "admin-products-new",
    path: newjYRvrVNXqHMeta?.path ?? "/admin/products/new",
    meta: newjYRvrVNXqHMeta || {},
    alias: newjYRvrVNXqHMeta?.alias || [],
    redirect: newjYRvrVNXqHMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/products/new.vue").then(m => m.default || m)
  },
  {
    name: editqHdmvclbQcMeta?.name ?? "admin-suppliers-id-edit",
    path: editqHdmvclbQcMeta?.path ?? "/admin/suppliers/:id()/edit",
    meta: editqHdmvclbQcMeta || {},
    alias: editqHdmvclbQcMeta?.alias || [],
    redirect: editqHdmvclbQcMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/suppliers/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: show8exgddYrsfMeta?.name ?? "admin-suppliers-id-show",
    path: show8exgddYrsfMeta?.path ?? "/admin/suppliers/:id()/show",
    meta: show8exgddYrsfMeta || {},
    alias: show8exgddYrsfMeta?.alias || [],
    redirect: show8exgddYrsfMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/suppliers/[id]/show.vue").then(m => m.default || m)
  },
  {
    name: indextyzo8qSzi8Meta?.name ?? "admin-suppliers",
    path: indextyzo8qSzi8Meta?.path ?? "/admin/suppliers",
    meta: indextyzo8qSzi8Meta || {},
    alias: indextyzo8qSzi8Meta?.alias || [],
    redirect: indextyzo8qSzi8Meta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: new36e5tLFmBhMeta?.name ?? "admin-suppliers-new",
    path: new36e5tLFmBhMeta?.path ?? "/admin/suppliers/new",
    meta: new36e5tLFmBhMeta || {},
    alias: new36e5tLFmBhMeta?.alias || [],
    redirect: new36e5tLFmBhMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/admin/suppliers/new.vue").then(m => m.default || m)
  },
  {
    name: blogZNyjgH1hklMeta?.name ?? "blog",
    path: blogZNyjgH1hklMeta?.path ?? "/blog",
    meta: blogZNyjgH1hklMeta || {},
    alias: blogZNyjgH1hklMeta?.alias || [],
    redirect: blogZNyjgH1hklMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: cartK246qPAK3VMeta?.name ?? "cart",
    path: cartK246qPAK3VMeta?.path ?? "/cart",
    meta: cartK246qPAK3VMeta || {},
    alias: cartK246qPAK3VMeta?.alias || [],
    redirect: cartK246qPAK3VMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: successyvtl40Wl91Meta?.name ?? "checkout-success",
    path: successyvtl40Wl91Meta?.path ?? "/checkout/success",
    meta: successyvtl40Wl91Meta || {},
    alias: successyvtl40Wl91Meta?.alias || [],
    redirect: successyvtl40Wl91Meta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: contact0xQw0YRwAOMeta?.name ?? "contact",
    path: contact0xQw0YRwAOMeta?.path ?? "/contact",
    meta: contact0xQw0YRwAOMeta || {},
    alias: contact0xQw0YRwAOMeta?.alias || [],
    redirect: contact0xQw0YRwAOMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: dashboard1n03aVKS7OMeta?.name ?? "dashboard",
    path: dashboard1n03aVKS7OMeta?.path ?? "/dashboard",
    meta: dashboard1n03aVKS7OMeta || {},
    alias: dashboard1n03aVKS7OMeta?.alias || [],
    redirect: dashboard1n03aVKS7OMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: guide05OM1pj1ybMeta?.name ?? "guide",
    path: guide05OM1pj1ybMeta?.path ?? "/guide",
    meta: guide05OM1pj1ybMeta || {},
    alias: guide05OM1pj1ybMeta?.alias || [],
    redirect: guide05OM1pj1ybMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: indexz8nWmkGksyMeta?.name ?? "index",
    path: indexz8nWmkGksyMeta?.path ?? "/",
    meta: indexz8nWmkGksyMeta || {},
    alias: indexz8nWmkGksyMeta?.alias || [],
    redirect: indexz8nWmkGksyMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginRrXON8r6HRMeta?.name ?? "login",
    path: loginRrXON8r6HRMeta?.path ?? "/login",
    meta: loginRrXON8r6HRMeta || {},
    alias: loginRrXON8r6HRMeta?.alias || [],
    redirect: loginRrXON8r6HRMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutysoAzVob8qMeta?.name ?? "logout",
    path: logoutysoAzVob8qMeta?.path ?? "/logout",
    meta: logoutysoAzVob8qMeta || {},
    alias: logoutysoAzVob8qMeta?.alias || [],
    redirect: logoutysoAzVob8qMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93lw7cuIfOSdMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93lw7cuIfOSdMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93lw7cuIfOSdMeta || {},
    alias: _91_46_46_46slug_93lw7cuIfOSdMeta?.alias || [],
    redirect: _91_46_46_46slug_93lw7cuIfOSdMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: shopJ3DBTEun14Meta?.name ?? "shop",
    path: shopJ3DBTEun14Meta?.path ?? "/shop",
    meta: shopJ3DBTEun14Meta || {},
    alias: shopJ3DBTEun14Meta?.alias || [],
    redirect: shopJ3DBTEun14Meta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: ordersAFxWsjvOQdMeta?.name ?? "supplier-orders",
    path: ordersAFxWsjvOQdMeta?.path ?? "/supplier/orders",
    meta: ordersAFxWsjvOQdMeta || {},
    alias: ordersAFxWsjvOQdMeta?.alias || [],
    redirect: ordersAFxWsjvOQdMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/supplier/orders.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedt7w4x3LS9XMeta?.name ?? "unauthorized",
    path: unauthorizedt7w4x3LS9XMeta?.path ?? "/unauthorized",
    meta: unauthorizedt7w4x3LS9XMeta || {},
    alias: unauthorizedt7w4x3LS9XMeta?.alias || [],
    redirect: unauthorizedt7w4x3LS9XMeta?.redirect || undefined,
    component: () => import("/home/sean/Development/dreamingunicorns/frontend/pages/unauthorized.vue").then(m => m.default || m)
  }
]