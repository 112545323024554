import * as draggable from 'vue-draggable-next';

export default defineNuxtPlugin((nuxtApp) => {

    //export default function (nuxt) {
    // TODO: No idea if we still need this?

    nuxtApp.hook("vue:app", (app) => {
       app.use(draggable);
    });

})
