import revive_payload_client_4sVQNw7RlN from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import check_outdated_build_client_8vK7RkfGxZ from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/sean/Development/dreamingunicorns/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/sean/Development/dreamingunicorns/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_HQNyLQlVwt from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import templates_pwa_client_914ac48c_VpC2wpFT3b from "/home/sean/Development/dreamingunicorns/frontend/.nuxt/templates.pwa.client.914ac48c.ts";
import chunk_reload_client_UciE0i6zes from "/home/sean/Development/dreamingunicorns/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/home/sean/Development/dreamingunicorns/frontend/plugins/auth.ts";
import google_analytics_pJGHEUVnWz from "/home/sean/Development/dreamingunicorns/frontend/plugins/google-analytics.js";
import mts_vue_plugin_aSRtlQ7uua from "/home/sean/Development/dreamingunicorns/frontend/plugins/mts-vue-plugin.ts";
import vue_draggable_next_j47zGshBFU from "/home/sean/Development/dreamingunicorns/frontend/plugins/vue-draggable-next.ts";
import vue_easy_lightbox_wHpYpTMm2i from "/home/sean/Development/dreamingunicorns/frontend/plugins/vue-easy-lightbox.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_HQNyLQlVwt,
  templates_pwa_client_914ac48c_VpC2wpFT3b,
  chunk_reload_client_UciE0i6zes,
  auth_vT9JWWT9pN,
  google_analytics_pJGHEUVnWz,
  mts_vue_plugin_aSRtlQ7uua,
  vue_draggable_next_j47zGshBFU,
  vue_easy_lightbox_wHpYpTMm2i
]