import {MtsVuePlugin} from "mts-vue-plugin";

export default defineNuxtPlugin((nuxtApp) => {
    const runtimeConfig = useRuntimeConfig()

    nuxtApp.vueApp.use(MtsVuePlugin, {
        imageService: runtimeConfig.imageServiceUrl,
        imageServiceOriginId: runtimeConfig.imageServiceOriginId
    })
})
