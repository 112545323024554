import {defineStore} from 'pinia'

const HTTP_STATUS_NOT_AUTH = [401, 419]

type AuthUser = {
    name: string
    email: string
    user_role?: string
    initials?: string
    supplier_details?: object
};

type LoginCredentials = {
    email: string
    password: string
};

export const useAuthStore = defineStore('auth', () => {
    const user = useLocalStorage('auth-user', null)
    const supplier = useLocalStorage('supplier-details', null)
    const isLoggedIn = useLocalStorage('is-logged-in', false)
    const isOwner = useLocalStorage('is-owner', false)
    const isSupplier = useLocalStorage('is-supplier', false)

    const actions = {
        async fetchCurrentUser() {
            try {
                await sendRequest(
                    $backendRequest(
                        '/api/user', {
                            redirectIfNotAuthenticated: false,
                        }),
                    (data) => {
                        user.value = data
                        supplier.value = data?.supplier_details
                        isLoggedIn.value = !!data
                        isOwner.value = data?.user_role === 'owner'
                        isSupplier.value = data?.user_role === 'supplier'
                    }
                )
            } catch (error: any) {
                user.value = null
                supplier.value = null
                isLoggedIn.value = false
                isOwner.value = false
                isSupplier.value = false

                if (HTTP_STATUS_NOT_AUTH.includes(error?.response?.status)) {
                    return null
                }

                throw error
            }
        },

        async refresh() {
            try {
                await this.fetchCurrentUser()
            } catch {
                this.clearUser()
            }
        },

        async login(credentials: LoginCredentials) {
            if (isLoggedIn.value) {
                return
            }

            await $backendRequest('/login', {
                method: 'post',
                body: credentials
            })

            await this.refresh()
        },

        async logout() {
            if (!isLoggedIn.value) {
                return
            }

            await $backendRequest('/logout', {
                method: 'post'
            })

            this.clearUser()
        },

        clearUser() {
            user.value = null
        },
    }

    if (process.client) {
        actions.refresh()
    }

    return {
        user,
        supplier,
        isLoggedIn,
        isOwner,
        isSupplier,
        ...actions,
    }
})
