
import * as ipxStaticRuntime$XInto3KR31 from '/home/sean/Development/dreamingunicorns/frontend/node_modules/@nuxt/image-edge/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 769,
    "lg": 1024,
    "xl": 1216,
    "xxl": 1408,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [
    "localhost",
    "dreamingunicorns.au",
    "s3-ap-southeast-2.amazonaws.com"
  ],
  "alias": {
    "/backend": "https://s3-ap-southeast-2.amazonaws.com/"
  },
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipxStatic']: { provider: ipxStaticRuntime$XInto3KR31, defaults: {} }
}
        